import React from 'react';
import { Link } from 'gatsby';
import {
    Card,
    Container,
    Divider,
    Feed,
    Header,
    Image,
    List,
    Menu,
    Segment,
} from 'semantic-ui-react';
import Layout from '../../components/layout';
import Helmet from '../../components/Helmet';
import * as DefaultTemplate from '../../components/LandingPages/DefaultTemplate';
import WhitePaperImage1 from '../../images/whitepapers/loans/what-are-debt-consolidation-loans.png';
import WhitePaperImage2 from '../../images/whitepapers/loans/kinds-of-debt-consolidation-loans.png';
import WhitePaperImage3 from '../../images/whitepapers/loans/what-is-refinancing.png';
import WhitePaperImage4 from '../../images/whitepapers/loans/kinds-of-refinancing.png';
import WhitePaperImage5 from '../../images/whitepapers/loans/pros-and-cons-of-debt-consolidation-loans.png';
import OptionsTemplate from '../../components/ContentPages/OptionsTemplate';
import AdvertiserDisclosurePopUp from '../../components/Disclosures/AdvertiserPopUp';

const offers = [
    {
        logo: '/assets/images/logos/logo-next-day-personal-loan.png',
        company: 'Next Day Personal Loans',
        link: 'https://www.ac934intrk.com/3J67C/363TCP/?sub3=60455',
        rating: 5,
        details: [{
            header: 'Loan Amount',
            content: 'Up to $40,000',
        }, {
            header: 'Interest Rate (APR)',
            content: 'Varies',
        }, {
            header: 'Loan Terms',
            content: 'Varies',
        }, {
            header: 'Loan Example',
            content: 'Show representative example',
            additional: '$5,000 loan for 24 months = $5,318.47 paid back (6% APR% effective APR)',
        }],
        mainValueProp: 'Personal loans from $1,000 to $40,000',
        benefits: [
            {
                item: 'A nation-wide marketplace of lenders and lending partners',
            },
            {
                item: 'Funds deposited directly to your bank as soon as the next business day',
            },
            {
                item: 'All credit types considered',
            },
            {
                item: 'Click here for official site, terms, and details',
                link: true,
            },
        ],
        review: ['Next Day Personal Loans has a nation-wide marketplace of lending partners. They currently offer loans up to $40,000 and they have a large number of lenders who specialize in credit card consolidation, debt consolidation loans, and debt settlement. They also work with lenders who provide home improvement loans, auto loans, and loans for continuing education. Many of these lenders work with less than perfect credit scores and can get funds quickly to qualified borrowers.', 'The loans are typically paid back in automated monthly or bi-monthly installments over an extended period of time. Next Day Personal Loans is not a lender, so repayment terms may vary, including origination fees and interest rates. It’s essential that you review all of these terms and conditions before entering into a loan agreement.',
        ],
    }, {
        logo: '/assets/images/logos/logo-lending-for-bad-credit.svg',
        company: 'Lending For Bad Credit',
        link: 'https://www.ac934intrk.com/3J67C/69KHPZ/?sub3=61446',
        rating: 5,
        details: [{
            header: 'Loan Amount',
            content: '$5,000 - $100,000',
        }, {
            header: 'Interest Rate (APR)',
            content: 'Varies',
        }, {
            header: 'Loan Terms',
            content: 'Varies',
        }, {
            header: 'Loan Example',
            content: 'Show representative example',
            additional: '$5,000 loan for 24 months = $5,318.47 paid back (6% APR% effective APR)',
        }],
        mainValueProp: 'A fast and easy way to find a loan!',
        benefits: [
            {
                item: 'Loans from $100-$40,000 available',
            },
            {
                item: 'Marketplace of lenders ready to help you!',
            },
            {
                item: 'Request a loan in under 2 minutes!',
            },
            {
                item: 'Click here for official site, terms, and details',
                link: true,
            },
        ],
        review: ['Lending for Bad Credit has a nation-wide marketplace of lending partners. They currently offer loans up to $40,000 and they have a large number of lenders who specialize in credit card consolidation, debt consolidation loans, and debt settlement. They also work with lenders who provide home improvement loans, auto loans, and loans for continuing education. Many of these lenders work with less than perfect credit scores and can get funds quickly to qualified borrowers.', 'The loans are typically paid back in automated monthly or bi-monthly installments over an extended period of time. Lending for Bad Credit is not a lender, so repayment terms may vary, including origination fees and interest rates. It’s essential that you review all of these terms and conditions before entering into a loan agreement.'],
    },
];

const featuredContent = [
    {
        slug: '/loans/lending-point-review/',
        image: 'america-lending.png',
        title: 'Lending Point Review',
    },
    {
        slug: '/understanding-debt-consolidation/',
        image: 'coin.jpg',
        title: 'Understanding Debt Consolidation: What It Does and How It Can Help',
    },
    {
        slug: '/debt-settlement/freedom-debt-relief/',
        image: 'freedom-debt-relief-review.jpg',
        title: 'Freedom Debt Relief Review',
    },

];


const relatedContent = [
    {
        slug: '/budgeting/',
        image: 'tips-for-sticking-to-a-budget.jpg',
        title: 'The Ultimate Guide to Budgeting',
    },
    {
        slug: '/how-to-get-out-of-debt/',
        image: 'how-to-get-out-of-debt.jpg',
        title: 'How to Get Out of Debt',
    },
];


const LoansPage = () => (
    <Layout showInformationDisclosure>
        <Helmet
            title="Debt Consolidation Loans"
            description="Regain control of your debt today. We'll show you how a debt consolidation loan, as part of an overall strategy, can help you pay off your debt."
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Hero>
                <>
                    <Segment attached>
                        <Container fluid className="hero hero__debt-consolidation" textAlign="center">
                            <Container text>
                                <AdvertiserDisclosurePopUp />
                                <Header as="h1" size="large">Debt Consolidation Loans</Header>
                                <Header as="h2" size="medium">Consolidate multiple loans into one monthly payment</Header>
                            </Container>
                        </Container>
                    </Segment>
                    <Menu pointing attached>
                        <Menu.Item href="/">Home</Menu.Item>
                        <Menu.Item href="/loans/" active>Debt Consolidation</Menu.Item>
                        <Menu.Item href="/debt-settlement/">Debt Settlement</Menu.Item>
                        <Menu.Item href="/credit-counseling/">Credit Counseling</Menu.Item>
                    </Menu>
                </>
            </DefaultTemplate.Hero>

            <DefaultTemplate.Featured>
                <OptionsTemplate offers={offers} />
                <Divider hidden />
                <Container textAlign="center">
                    <Header size="large" textAlign="center">
                        Learn About Debt
                    </Header>
                    <div className="content--featured">
                        <Feed>
                            {featuredContent.map((item, idx) => (
                                <Feed.Event
                                    key={idx.toString() + item.slug}
                                    as="a"
                                    href={item.slug}
                                    image={`/media/resize/200x150/${item.image}`}
                                    content={item.title}
                                />
                            ))}

                        </Feed>
                    </div>
                    <div className="content--featured__desktop">
                        <Card.Group stackable itemsPerRow={3}>
                            {featuredContent.map((item, idx) => (
                                <Card key={idx.toString() + item.slug} as={Link} to={item.slug}>
                                    <Image src={`/media/resize/400x300/${item.image}`} wrapped ui={false} />
                                    <Card.Content>
                                        <Card.Header>
                                            {item.title}
                                        </Card.Header>
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    </div>
                </Container>
            </DefaultTemplate.Featured>

            <DefaultTemplate.Body>
                <Divider hidden className="half-size" />
                <Header as="h3">What are Debt Consolidation Loans?</Header>
                <p><Image src={WhitePaperImage1} alt="What are Debt Consolidation Loans?" /></p>
                <p>
                    When you have
                    {' '}
                    <a href="https://www.badcredit.org/review/personal-loans/" target="_blank" rel="noopener noreferrer">several loans</a>
                    , it can be easier to pay them by turning them into a single one — a
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=loans&sub2=debt-consolidation-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        debt consolidation loan
                    </a>
                    .
                </p>
                <p>A bank or other lender first authorizes and provides you with a new loan that you then use to pay off your outstanding balances. You repay it on a monthly basis, but now you&rsquo;re working with one payment per month. Sometimes these are secured with your home. In general, you’ll replace your old loan’s interest rate with a new, lower one.</p>
                <p>These loans will be in one of two categories: secured and unsecured. Secured loans are those backed by property you own, like a house or a car. Unsecured are backed by your promise to repay them.</p>

                <Divider hidden className="half-size" />
                <Header as="h3">Different Kinds of Consolidation Loans</Header>
                <p><Image src={WhitePaperImage2} alt="Kinds of Debt Consolidation Loans" /></p>
                <p>Finance companies began increasing advertising of debt consolidation services in the 1950s and 1960s. By the 1990s, it had become common. Here are what some of the options look like:</p>
                <p>
                    <strong>Bank/Credit Union Personal Loan or Home Equity Line of Credit (Secured):</strong>
                    <br />
                    Home equity loans tend to have fixed interest rates and must be repaid over a set number of months. A Home Equity Line of Credit (HELOC) typically has a variable interest rate, which means the rate changes over time, and as long as you make your payments you can borrow against your home’s equity. You’ll often need a minimum credit score — typically starting in the high 600s — to qualify for them.
                </p>
                <p>
                    <strong>Bank/Credit Union Personal Loan (Unsecured):</strong>
                    <br />
                    Your credit rating will affect the size and term of the loan, as well as the interest rate. A point of reference when it comes to interest: in February 2012, the rate for a 24-month
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=loans&sub2=personal-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        personal loan
                    </a>
                    {' '}
                    was 10.88%, according to the Federal Reserve.
                </p>
                <p>
                    <strong>Peer Lending:</strong>
                    <br />
                    Peer lenders are independent companies that essentially broker
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=loans&sub2=consolidation-loans"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        consolidation loans
                    </a>
                    {' '}
                    between a borrower and an individual or group of investors. These investors pick from loan requests posted by would-be borrowers. The interest rates offered have a lot to do with why investors engage in this type of lending. Once enough investors have agreed to contribute to a request, the money is transferred to the borrower by the lending company. Typically, borrowers can ask for up to $25,000, and the payback period is usually set at a maximum of three to five years. Interest rates, according to a ConsumerAction.org report in 2012, range from 5.65%–35.80%. (the numbers in the report were based on those posted by three key providers: LendingClub, Peerform, and Prosper).
                </p>
                <p>
                    <strong>Credit Card Balance Transfers:</strong>
                    <br />
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&sub1=loans&sub2=credit-card"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Credit card
                    </a>
                    {' '}
                    companies often offer low interest rates to attract new members. The idea is to use the new lower-rate card to pay off a number of higher-rate balances on others. You can use these to consolidate, but you only get to keep the low rate on the new card if you pay on time. In many cases, the offer is a short-term deal, meaning that to take advantage of it one has to pay off the consolidated balances before the interest resets to a higher rate. A study by CardHub.com found a range of offers for zero percent interest credit cards that kept the introductory rate in place for between six months and 18 months.
                </p>
                <p>
                    <strong>Student Loan Consolidation:</strong>
                    <br />
                    In general, you can’t consolidate federal
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=loans&sub2=student-loans"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student loans
                    </a>
                    {' '}
                    with private student loans. On the federal side, interest rates are a weighted average of all the rates on all your federal loans, capped at 8.25%. Rate discounts used to be available, but since July 2010 there is only one: a 0.25% break if you set up automatic payments.
                </p>

                <Divider hidden className="half-size" />
                <Header as="h3">What is Refinancing?</Header>
                <p><Image src={WhitePaperImage3} alt="restructure mortgage with Debt Consolidation Loans" /></p>
                <p>Restructuring what you owe on your mortgage is called refinancing. In both debt consolidation and refinancing, you are usually combining more than one balance. For example, if you have both a mortgage and a home equity loan, refinancing these would create one new balance with one new payment due each month. Much like consolidation, the goal is to secure a lower interest rate in the process.</p>
                <p>
                    Refinancing represented 71.4% of all the mortgages originated in 2012. Interest rates have been friendly to borrowers, in recent years. Look at the following table, compiled from an Inside Mortgage Finance report and data from Freddie Mac, to see how average rates on 30-year mortgages have dropped in the past half decade.
                </p>
                <List bulleted>
                    <List.Item>2008: 6.03%</List.Item>
                    <List.Item>2009: 5.04%</List.Item>
                    <List.Item>2010: 4.69%</List.Item>
                    <List.Item>2011: 4.45%</List.Item>
                    <List.Item>2012: 3.66%</List.Item>
                </List>

                <Divider hidden className="half-size" />
                <Header as="h3">Different Kinds of Refinancing</Header>
                <p><Image src={WhitePaperImage4} alt="refi options for Debt Consolidation Loans" /></p>
                <p>The history of refinancing mortgages appears to date back to at least the 1930s, when 30-year mortgages came along. Bankers attracted new borrowers with low-interest deals whenever rates dropped. Here are some key examples of refinancing:</p>
                <p>
                    <strong>Standard:</strong>
                    {' '}
                    If you want to replace your current interest rate with a lower one, use a standard refinancing plan. You&rsquo;ll likely pay a closing cost, but you can also expect an edge on your rate.
                </p>
                <p>
                    <strong>Cash-Out:</strong>
                    {' '}
                    Under this plan, the difference between the old mortgage amount and the new one comes to you as cash. This money can be used to pay down other debts such as car loans and credit cards, but the interest rate on the new mortgage tends to be higher. The new repayment term is typically 30 years.
                </p>
                <p>
                    <strong>Cash-In:</strong>
                    {' '}
                    Instead of a cash-out, you pay a lump sum at the start of a new mortgage, lowering both the balance and the monthly payment afterward.
                </p>
                <p>
                    <strong>Home Affordable:</strong>
                    {' '}
                    The U.S. Treasury Department and the Department of Housing and Urban Development introduced this program during the mortgage crisis of the late 2000s. Certain struggling homeowners can qualify to take a new loan for 25 percent more than their home’s value, securing lower interest rates.
                </p>
                <p>
                    <strong>Short:</strong>
                    {' '}
                    Some lenders will create a new mortgage to replace one that’s in trouble. The idea is that the lender would rather keep the borrower than lose them to foreclosure.
                </p>

                <Divider hidden className="half-size" />
                <Header as="h3">Loans or Refinancing? Pros and Cons</Header>
                <p><Image src={WhitePaperImage5} alt="positives and negatives of Debt Consolidation Loans" /></p>
                <p>No matter what you choose to do, here are some other important details to consider:</p>
                <List bulleted>
                    <List.Item>
                        <strong>Secured Loans and the Home:</strong>
                        {' '}
                        Lowering your credit score is one thing. Losing your house is another. If debt consolidation or refinancing is only a stopgap measure, experts say bankruptcy is sometimes an option to consider over and above new loans.
                    </List.Item>
                    <List.Item>
                        <strong>Interest Over Time:</strong>
                        {' '}
                        Lower monthly payments and lower interest rates can be deceptive. Taking a mortgage that you have paid for 10 years and replacing it with a new 30-year balance could mean paying more in interest over time than you would have in the first place. A debt consolidation calculator, such as this one, can help.
                    </List.Item>
                    <List.Item>
                        <strong>“Hidden” Costs:</strong>
                        {' '}
                        Closing costs to refinance are often 3%–6% of the principal, among other fees.
                    </List.Item>
                    <List.Item>
                        <strong>Cash-Out Hazards:</strong>
                        {' '}
                        Some experts will tell you that the cash-out option is only for improvements to a home or for personal emergencies. Recent trends suggest that borrowers are moving away from the option. In the fourth quarter of 2012, Freddie Mac reported 84% of homeowners who refinanced their mortgage either maintained about the same loan amount or lowered their principal by the cash-in method.
                    </List.Item>
                    <List.Item>
                        <strong>Credit Card Transfers and the Credit Score:</strong>
                        {' '}
                        Opening new accounts with credit card companies tends to lower your credit score at first. Be aware of that. Scores climb again, however, as long as you make payments on time and avoid maxing out.
                    </List.Item>
                </List>
            </DefaultTemplate.Body>

            <DefaultTemplate.PostSidebar>
                <Header size="medium">
                    Related Content
                </Header>
                <Feed>
                    {relatedContent.map((item, idx) => (
                        <Feed.Event
                            key={item.slug + idx.toString()}
                            as="a"
                            href={item.slug}
                            image={`/media/resize/200x150/${item.image}`}
                            content={item.title}
                        />
                    ))}
                </Feed>
            </DefaultTemplate.PostSidebar>

        </DefaultTemplate.Wrapper>
    </Layout>

);

export default LoansPage;
